<template>
  <div class="workPlace">
    <a-page-header class="text2" :title="view_data.title?view_data.title:'配置工作台'"
      style="border-bottom: 1px solid rgb(235, 237, 240)">
      <template slot="extra">
        <a-button @click="save()">
          保存
        </a-button>
        <a-button @click="goUrl('-1')" type="primary">
          返回
        </a-button>
      </template>
    </a-page-header>
    <div id='box' class="dragForm">
      <!--  -->
      <!--  -->
      <!--  -->
      <div class="left">
        <div class="title">
          <p class="page_title">
            <img src="@/assets/base_img/组件化@2x.png" alt="">
            <span>组件库</span>
          </p>
          <p class="page_ic">点击或拖拽添加组件</p>
        </div>
        <draggable class="btnFlexBox" v-model="leftList" v-bind="dragOptions" :move="onMove"
          :options="{ sort: false, group: { name: 'field', pull: 'clone', put: false } }">

          <a-button class="btnFlex" @click="addItem(item,index)" v-for="(item,index) in leftList" :key="index">
            <span class="btnFlex_imgBox">
              <img width="30px" :src="item.img" alt="">
            </span>
            <span class="btnFlex_Txt">
              {{ item.name }}
            </span>
          </a-button>
        </draggable>

      </div>

      <!--  -->
      <!--  -->
      <!--  -->

      <div class="center">
        <div class="mobile">
          <div :style=" 'background-color:' + meeting_data.background_color " class="mobile_bgc">
            <img v-if="meeting_data.background_img_path" width="100%" height="100%"
              :src="meeting_data.background_img_path" alt="">
          </div>
          <draggable class="draggableBox" v-model="centerShow" v-bind="dragOptions" @choose="chooseEvent"
            @end="endEvent" @add="addEvent" group="field">

            <div :class="activeIndex === index ? 'component active' : 'component'" @click="getIndex(index,item.type)"
              v-for="(item, index) in centerShow" :key="index">
              <component @clearItem='clearItem' @changeWindow='changeWindow' :is="item.type" :activeIndex='activeIndex' :index="index" :key="index">
                <a-button @click="clearItem(item, index)" class="solt"
                  :type="activeIndex === index ? 'primary' : 'Default'">
                  {{ item.name }}
                  <a-icon style="margin-left:10px !important;" type="close" />
                </a-button>
              </component>
            </div>

          </draggable>
        </div>
      </div>

      <!--  -->
      <!--  -->
      <!--  -->

      <div title="长按拖拽改变大小" id="setWidth" class="setWidth"></div>
      <div id="setWidth_right" class="right">
        <div v-if="centerShow[0]" class="right_title">{{centerShow[activeIndex].name}}</div>
        <articleForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex" v-if="type == 'articleBox'">
        </articleForm>
        <swiperForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex" v-if="type === 'banner'">
        </swiperForm>
        <tuwendaohangForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'tuwendaohang'"></tuwendaohangForm>
        <jiugonggeForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex" v-if="type === 'jiugongge'">
        </jiugonggeForm>
        <dibudaohangForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'dibudaohang'"></dibudaohangForm>
        <dingbudaohangForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'dingbudaohang'"></dingbudaohangForm>
        <shipinhejiForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'shipinheji'"></shipinhejiForm>
        <rollForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex" v-if="type === 'roll'">
        </rollForm>
        <pageTitleForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex" v-if="type === 'pageTitle'">
        </pageTitleForm>
        <videoForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex" v-if="type === 'videoBox'">
        </videoForm>
        <second_articleForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'second_articleList'">
        </second_articleForm>
        <home_dengfenForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'home_dengfen'"></home_dengfenForm>
        <home_TabForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex" v-if="type === 'home_Tab'">
        </home_TabForm>
        <second_videoListForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'second_videoList'">
        </second_videoListForm>
        <second_cloudBoothForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'second_cloudBooth'">
        </second_cloudBoothForm>
        <setForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex" v-if="type === 'formBox'">
        </setForm>
        <doctor_taskForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'doctor_taskBox'">
        </doctor_taskForm>
        <upload_fileForm @changeWindow='changeWindow' :key="activeIndex" :index="activeIndex"
          v-if="type === 'upload_file'">
        </upload_fileForm>
      </div>
    </div>

  </div>
</template>

<script>
import { getPageData, setPageData } from "@/api/meetApi";
import Vue from "vue";
import draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { draggable },
  name: "workPlace",
  data() {
    return {
      debounceTimer: null,
      dragOptions: { animation: 500 },
      activeIndex: 0,
      type: "",
      centerShow: [],
      view_data: {},
      leftList: [
        {
          type: "banner",
          name: "轮播图",
          icon: "icon-shouyelunbotu",
          img: require('@/assets/base_img/icon_轮播图@2x.png')
        },
        {
          type: "pageTitle",
          name: "图片",
          icon: "icon-tupian",
          img: require('@/assets/base_img/icon_图片@2x.png')
        },
        {
          type: "home_dengfen",
          name: "等分列表",
          icon: "icon-Z-fenleidaohang",
          img: require('@/assets/base_img/icon_等分列表@2x.png')
        },
        {
          type: "home_Tab",
          name: "Tab",
          icon: "icon-Z-fenleidaohang",
          img: require('@/assets/base_img/tab切换.png')
        },
        {
          type: "tuwendaohang",
          name: "图文导航",
          icon: "icon-tuwendaohang",
          img: require('@/assets/base_img/icon_图文导航@2x.png')
        },
        {
          type: "second_videoList",
          name: "直播列表",
          icon: "icon-erji-wangluotuopu",
          img: require('@/assets/base_img/icon_视频列表@2x.png')
        },
        {
          type: "shipinheji",
          name: "视频列表",
          icon: "icon-shipinheji",
          img: require('@/assets/base_img/icon_二级视频@2x.png')
        },
        {
          type: "second_articleList",
          name: "图文列表",
          icon: "icon-erji-ziyuanji",
          img: require('@/assets/base_img/icon_二级日程@2x.png')
        },
        // {
        //   type: "second_cloudBooth",
        //   name: "云展台列表",
        //   icon: "icon-erji-ziyuanji",
        //   img: require('@/assets/base_img/icon-subnetwork.png')
        // },
        // {
        //   type: "jiugongge",
        //   name: "九宫格",
        //   icon: "icon-jiugongge-line-",
        //   img: require('@/assets/base_img/icon_九宫格@2x.png')
        // },
        // {
        //   type: "dingbudaohang",
        //   name: "顶部导航",
        //   icon: "icon-dingbudaohang1",
        //   img: require('@/assets/base_img/icon_九宫格@2x.png')
        // },
        // {
        //   type: "dibudaohang",
        //   name: "底部栏",
        //   icon: "icon-dibudaohang",
        //   img: require('@/assets/base_img/icon_底部栏@2x.png')
        // },
        // {
        //   type: "roll",
        //   name: "滚动条",
        //   icon: "icon-m-gundongwenzi",
        //   img: require('@/assets/base_img/icon_滚动条@2x.png')
        // },
        // {
        //   type: "videoBox",
        //   name: "视频",
        //   icon: "icon-shipin",
        //   img: require('@/assets/base_img/icon_滚动条@2x.png')
        // },

        {
          type: "articleBox",
          name: "图文",
          icon: "icon-article",
          img: require('@/assets/base_img/icon_图文@2x.png')
        },
        {
          type: "formBox",
          name: "表单",
          icon: "icon-m-gundongwenzi",
          img: require('@/assets/base_img/表单 (1).png')
        },
        {
          type: "doctor_taskBox",
          name: "专家任务",
          icon: "icon-erji-ziyuanji",
          img: require('@/assets/base_img/icon-subnetwork.png')
        },
        // {
        //   type: "upload_file",
        //   name: "文件上传",
        //   icon: "icon-erji-ziyuanji",
        //   img: require('@/assets/base_img/上传.png')
        // },
      ],
      meeting_data: {}
    };
  },
  async created() {
    let data = await getPageData({ view_id: this.$route.query.view_id });
    this.centerShow = data.payload.component_arr;
    this.meeting_data = data.payload.meeting_data
    this.changeBGC()
    this.view_data = data.payload.view_data
    this.workPlace.okForm = JSON.parse(JSON.stringify(this.centerShow));
    this.centerShow[0]
      ? (this.type = this.centerShow[0].type)
      : (this.type = "");

    // //
    // //
    // // 引入src/components/workplace中所有组件
    const requireComponents = require.context(
      "@/components/workPlace",
      true,
      /\.vue/
    );
    requireComponents.keys().forEach((fileName) => {
      const reqCom = requireComponents(fileName);
      const reqComName = reqCom.name || fileName.replace(/\.\/(.*)\.vue/, "$1");
      Vue.component(reqComName, reqCom.default || reqCom);
    });
    // //
    // //
    // // 引入./components/workplace中所有组件
    const requireComponents1 = require.context("./components", true, /\.vue/);
    requireComponents1.keys().forEach((fileName) => {
      const reqCom = requireComponents1(fileName);
      const reqComName = reqCom.name || fileName.replace(/\.\/(.*)\.vue/, "$1");
      Vue.component(reqComName, reqCom.default || reqCom);
    });
  },
  methods: {
    ...mapActions("workPlace", ["set_workPlace"]),
    clearItem(item, index) {
      this.centerShow.splice(index, 1);
      this.set_workPlace({ index: this.activeIndex, num: 1 });
      this.activeIndex = this.activeIndex - 1;
      if (this.activeIndex < 0) {
        this.type = "";
        return (this.activeIndex = 0);
      }
      this.type = this.centerShow[this.activeIndex].type;
    },
    // move回调方法 
    // 添加表单组件时，因为所有表单组件共用一个vuex所以同一个页面不支持多个表单组件！ 
    // 注意 onMove 方法在鼠标按下时会不断执行!
    onMove(e, originalEvent) {
      // e.draggedContext: 被拖拽的元素
      // e.index: 被拖拽的元素的序号
      // e.element: 被拖拽的元素对应的对象
      // e.futureIndex: 预期位置、目标位置
      // e.relatedContext: 将停靠的对象
      // e.index: 目标停靠对象的序号
      // e.element: 目标的元素对应的对象
      // e.list:  目标数组
      // e.component: 将停靠的vue组件对象
      try {
        this.workPlace.okForm.forEach((i) => {
          if (e.draggedContext.element.type == 'formBox' && i.type == 'formBox') {
            throw new Error(false);
          }
        })
      } catch (error) {
        clearTimeout(this.debounceTimer);

        this.debounceTimer = setTimeout(() => {
          this.$message.error("请勿重复添加表单!")
        }, 100);
        return false
      }
      return true;
    },

    // 直接点击添加
    addItem(item, index) {

      // 添加表单组件时，因为所有表单组件共用一个vuex所以同一个页面不支持多个表单组件！
      // 添加表单组件时，因为所有表单组件共用一个vuex所以同一个页面不支持多个表单组件！
      // 添加表单组件时，因为所有表单组件共用一个vuex所以同一个页面不支持多个表单组件！ 
      try {
        this.workPlace.okForm.forEach((i) => {
          if (item.type == 'formBox' && i.type == 'formBox') {
            throw new Error(false);
          }
        })
      } catch (error) {
        return this.$message.error("请勿重复添加表单!")
      }

      this.$store.state.formwork.formwork = []
      item.options = this.workPlace[item.type];
      this.centerShow.push(item);
      this.type = item.type;
      this.activeIndex = this.centerShow.length - 1;
      this.addVuex();
    },
    chooseEvent(argument) {
      this.activeIndex = argument.oldIndex;
      this.type = this.centerShow[argument.oldIndex].type;
    },
    endEvent(argument) {
      this.activeIndex = argument.newIndex;
      let changeObj = JSON.parse(
        JSON.stringify(this.workPlace.okForm[argument.oldIndex])
      );
      this.set_workPlace({ index: argument.oldIndex, num: 1 });
      let index = argument.newIndex;
      this.set_workPlace({
        index: index,
        num: 0,
        arr: changeObj,
      });
    },
    addEvent(argument) {
      this.activeIndex = argument.newIndex;
      this.type = this.centerShow[argument.newIndex].type;
      this.addVuex();
    },
    addVuex() {
      let newObj = JSON.parse(
        JSON.stringify(this.centerShow[this.activeIndex])
      );
      newObj.options = JSON.parse(JSON.stringify(this.workPlace[this.type]))
      this.set_workPlace({
        type: this.type,
        index: this.activeIndex,
        num: 0,
        arr: newObj,
      });
    },
    getIndex(index, type) {
      this.activeIndex = index;
      this.type = type;
    },
    async save() {
      console.log(this.workPlace.okForm)
      try {
        await setPageData({ view_data: this.view_data, component_arr: this.workPlace.okForm });
        this.$message.success("保存成功！");
      } catch (error) {
        this.$message.error("保存失败！");
      }
    },
    goUrl(url) {
      var _this = this;

      this.$confirm({
        title: "确认返回吗？",
        content: "请确认是否已保存所做编辑？",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          _this.$router.go(url);
        },
        onCancel() { },
      });
    },
    changeBGC() {
      if (this.meeting_data.background_color) {
        document.querySelectorAll('.draggableBox')[0].style.backgroundColor = this.meeting_data.background_color
      }
    },
    changeWindow() {
      this.$forceUpdate()
      console.log(this.$store.state.formwork.formwork) 
    }
  },
  computed: {
    ...mapGetters(["workPlace"]),
  },
  mounted() {
    // 鼠标拖拽时改变右侧宽度
    var resize = document.getElementById("setWidth");
    var right = document.getElementById("setWidth_right");
    var box = document.getElementById("box");
    resize.onmousedown = function (e) {
      document.body.style.cursor = "w-resize";
      var startX = e.clientX;
      resize.left = resize.offsetLeft;
      document.onmousemove = function (e) {
        var endX = e.clientX;

        var moveLen = resize.left + (endX - startX);
        var maxT = box.clientWidth - resize.offsetWidth;
        if (moveLen < 850) moveLen = 850;
        if (moveLen > maxT - 350) moveLen = maxT - 350;

        resize.style.left = moveLen;
        right.style.width = box.clientWidth - moveLen - 5 + "px";
      };
      document.onmouseup = function (evt) {
        document.body.style.cursor = "auto";
        document.onmousemove = null;
        document.onmouseup = null;
        resize.releaseCapture && resize.releaseCapture();
      };
      resize.setCapture && resize.setCapture();
      return false;
    };
  },
};
</script>

<style lang="less" scoped>
.form-work-left,
.form-work-center {
  height: 100%;
  border-right: 1px solid #eaeaea;
}
.form-work-center {
  padding: 20px;
}
.workPlace {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.dragForm {
  display: flex;
  flex: 1;
  overflow: hidden;
  .left {
    width: 320px;
    border-right: 1px solid #ccc;
    .title {
      box-shadow: 0px 2px 8px 0px rgba(183, 193, 202, 0.39);
      border-bottom: 1px solid #eff0f2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 80px;
      padding-left: 15px;
      span {
        margin-left: 5px;
      }
    }

    button[ant-click-animating-without-extra-node]:after {
      border: 0 none;
      opacity: 0;
      animation: none 0 ease 0 1 normal;
      outline: none;
      & {
        outline: 0;
      }
    }
  }
  .center {
    flex: 1;
    overflow-y: auto;
    padding: 40px;
    background-color: #f9fafc;
    .mobile {
      position: relative;
      z-index: 0;
      width: 375px;
      min-height: 667px;
      margin: auto;
      box-shadow: rgba(156, 158, 163, 77%) 0px 4px 16px 0px;
    }
  }
  .right {
    border-left: 1px solid #ccc;
    width: 350px;
    overflow: auto;
  }
}
.btnFlexBox {
  margin-top: 20px;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
}
.workPlace .dragForm .btnFlex {
  width: 33.3%;
  height: 85px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: unset;
  box-shadow: unset;
  padding: 0 10px;
  margin-bottom: 5px;
  .btnFlex_Txt {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .btnFlex_imgBox {
    height: 56px;
    width: 100%;
    background: rgba(24, 144, 255, 0.05);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  i {
    font-size: 32px;
  }
}
.component {
  position: relative;
  // border: 3px solid #fff;
}
.active {
  border: 3px solid #1890ff !important;
}
.solt {
  position: absolute;
  top: 0;
  left: 385px;
}
.component:hover {
  border: 3px dashed #1890ff;
  .solt {
    background-color: #1890ff;
    color: #fff;
  }
}
.draggableBox {
  width: 100%;
  height: 100%;
  min-height: 667px;
  padding-bottom: 0.1px;
}
.right_title {
  line-height: 50px;
  background-color: #f2f5fd;
  font-size: 18px;
  color: #000;
  padding-left: 15px;
}
.setWidth {
  width: 8px;
  background-color: #efeeee;
  cursor: w-resize;
}
.bottom_img {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 375px;
  img {
    width: 100%;
  }
}
.page_title {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  margin-bottom: 0;
  span {
    vertical-align: middle;
  }
}
.page_ic {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #86909d;
  margin-bottom: 0;
}
.mobile_bgc {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>