<template>
  <div class="second_articleList shipinheji">

    <div v-if='options.data.pageCss.showType==1' :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" class="dataBox">
      <div class="list">

        <div class="date_img_title" v-if='options.data.pageCss.showDateName&&options.data.dateImg'>
          <img width="60px" :src="options.data.dateImg" alt="">
        </div>

        <div class="noData" v-if='!options.data.menuArr||!options.data.menuArr[0]'>
          <img width="100%" src="@/assets/auto_img/videolist (3).png" alt="">
        </div>

        <div :style="'padding:'+options.data.pageCss.page_css_interval+'px 0'" v-for="item in options.data.menuArr"
          :key="item.id" class="type imgBr_flex">

          <div class="imgBr imgBox">
            <img v-if="item.list_cover_path" width="100%" height="100%" :src="item.list_cover_path" alt="">
            <img v-if="!item.list_cover_path" width="100%" height="100%" src="@/assets/ex_nav.webp" alt="">
          </div>

          <div class="msg_border">
            <p class="video_title">
              <img height="18px" v-if="options.data.beginImg&&item.status==0" :src="options.data.beginImg" alt="">
              <img height="18px" v-if="options.data.doingImg&&item.status==1" :src="options.data.doingImg" alt="">
              <img height="18px" v-if="options.data.endImg&&item.status==2" :src="options.data.endImg" alt="">
              <span class="title">{{item.title}}</span>
            </p>
            <p style="flex:1"></p>
            <!-- <p v-if='options.data.showUV' class="imgBr_flex_box">1人再看</p> -->
            <p class="video_message">
              <!-- <a-tag color="orange">
                    回放
                  </a-tag> -->
              <span>{{item.begin_time|time('YYYY-MM-DD HH:mm')}}</span>
            </p>
          </div>

        </div>

      </div>
    </div>

    <div v-if='options.data.pageCss.showType==2' :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" class="dataBox">
      <div class="list">

        <div class="date_img_title" v-if='options.data.pageCss.showDateName&&options.data.dateImg'>
          <img width="60px" :src="options.data.dateImg" alt="">
        </div>

        <div class="noData" v-if='!options.data.menuArr||!options.data.menuArr[0]'>
          <img width="100%" src="@/assets/auto_img/videolist (1).png" alt="">
        </div>

        <div v-for="i in options.data.menuArr" :key="i.id"
          :style="'padding:'+options.data.pageCss.page_css_interval+'px 0'" class="item_s">

          <div class="item_title">
            <span class="item_a">
              <span style="margin-right:5px;" v-if="options.data.beginImg&&i.type==1">
                <img height="18px" v-if="i.type==1&&options.data.beginImg&&i.status==0" :src="options.data.beginImg"
                  alt="">
                <img height="18px" v-if="i.type==1&&options.data.doingImg&&i.status==1" :src="options.data.doingImg"
                  alt="">
                <img height="18px" v-if="i.type==1&&options.data.endImg&&i.status==2" :src="options.data.endImg" alt="">
              </span>
              <span class="title">{{i.title}}</span>
            </span>
            <span class="item_b">
              <img height="18px" v-if='i.type==3&&i.poster_path' :src="options.data.detailsImg" alt="">
              <img height="18px" v-if='i.type==1' :src="options.data.detailsImg" alt="">
            </span>
          </div>

          <div class="time flex_box_message flex_list_video">
            <div v-if="i.begin_time" class="mr10 flex_box_item ">
              <span>
                <img src="@/assets/auto_img/时间@2x.png" alt="">
              </span>
              <span>{{i.begin_time | time('YYYY-MM-DD HH:mm')}}-{{i.end_time | time('HH:mm')}}</span>
            </div>
            <div v-if="i.hospital" class="mr10 flex_box_item">
              <span class="iconfont icon-yiyuanjieshao_xianxing"></span>
              <span>{{i.hospital}}</span>
            </div>
            <div v-if="i.speaker" class="mr10 flex_box_item">
              <span>
                <img src="@/assets/auto_img/术者@2x.png" alt="">
              </span>
              <span>{{i.speaker}}</span>
            </div>
          </div>
          <div style="margin-top:0px" class="time flex_box_message">
            <div v-if="i.address" class="mr10 flex_box_item">
              <span><img src="@/assets/auto_img/地点@2x.png" alt=""></span>
              <span>{{i.address}}</span>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div v-if='options.data.pageCss.showType==3' :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" class="dataBox">
      <div class="list">

        <div class="date_img_title" v-if='options.data.pageCss.showDateName&&options.data.dateImg'>
          <img width="60px" :src="options.data.dateImg" alt="">
        </div>

        <div class="noData" v-if='!options.data.menuArr||!options.data.menuArr[0]'>
          <img width="100%" src="@/assets/auto_img/videolist (2).png" alt="">
        </div>

        <div :style="'padding:'+options.data.pageCss.page_css_interval+'px 0'" v-for="i in options.data.menuArr"
          :key="i.id" class="type pb12">
          <img v-if="i.list_cover_path" class="imgBr" width="100%" :src="i.list_cover_path" alt="">
          <img v-if="!i.list_cover_path" class="imgBr" width="100%" src="@/assets/bg.png" alt="">
          <p class="video_title video_title_01 p12">
            <img height="18px" v-if="options.data.beginImg&&i.status==0" :src="options.data.beginImg" alt="">
            <img height="18px" v-if="options.data.doingImg&&i.status==1" :src="options.data.doingImg" alt="">
            <img height="18px" v-if="options.data.endImg&&i.status==2" :src="options.data.endImg" alt="">
            <span class="title">{{i.title}}</span>
          </p>
          <p class="video_message p12">
            <!-- <a-tag color="orange">
              回放
            </a-tag> -->
            <span>{{i.begin_time|time('YYYY-MM-DD HH:mm')}}</span>
            <!-- <span v-if='options.data.showUV'>12人再看</span> -->
          </p>
        </div>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import { geiVideoList } from '@/api/meetApi.js';
import { mapGetters, mapActions } from "vuex";
export default {
  name: "second_articleList",
  props: ["index", "activeIndex"],
  data() {
    return {
      list: []
    };
  },
  computed: {
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      this.$forceUpdate()
      return this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: this.workPlace.second_articleList.data };
    },
  },

  watch: {
    okForm: {
      handler(newVal, oldVal) {
        this.$forceUpdate()
      },
      deep: true,
    },
  },

  created() {
    console.log(this.options)
    // this.getList()
  },
  methods: {
    async getList() {
      let data = await geiVideoList({ meeting_id: this.$route.query.meeting_id })
      this.list = data.payload.data
      console.log(data)
    }

  }
};
</script>

<style lang="less" scoped>
.flex_list_video {
  display: flex;
}
.flex_box_message {
  .flex_box_item {
    display: flex;
    height: 18px;
    span {
      font-size: 12px;
      display: flex;
      height: 18px;
      align-items: center;
      line-height: normal !important;
    }
    img {
      margin-right: 5px;
      height: 12px;
    }
  }
}
.poster {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  z-index: 10;
}
.noData {
  text-align: center;
  color: #888888;
}
.date_img_title {
  margin-bottom: 16px;
}
.second_articleList {
  display: flex;
  flex-direction: column;
}

.dataBox {
  flex: 1;
  position: relative;
  margin-bottom: 0px;
  .list {
    .item:last-child {
      margin-bottom: 0;
    }
    .item_s:last-child {
      border-bottom: 0px solid #000;
      margin-bottom: 0;
    }
  }
}

.icon {
  width: 100%;
  text-align: center;
  font-size: 0px;
  margin: 10px 0;
  img {
    width: 45%;
  }
}

.item {
  margin-bottom: 12px;
}

.title_img {
  margin-bottom: 8px;
}

.title {
  // margin-left: 5px;
  font-weight: 500;
  font-size: 16px;
  color: #222222;
}

.item_title {
  display: flex;
  .item_a {
    flex: 1;
    img {
      vertical-align: middle;
      position: relative;
      top: -2px;
    }
  }
  .item_b {
    margin-left: 5px;
    position: relative;
    top: -1px;
    z-index: 8;
  }
}

.item_s {
  border-bottom: 0.5px solid #e5e6e6;
  // margin-bottom: 10px;
  // padding-bottom: 10px;
  position: relative;
}

.time {
  margin-top: 3px;
  font-size: 13px;
  font-weight: 400;
  color: #888888;
  .iconfont {
    font-size: 13px;
    margin-right: 4px;
    font-weight: 400;
  }
}

.mr10 {
  margin-right: 10px;
}

.flr {
  float: right;
}

@media screen and (max-width: 768px) {
  .second_articleList {
    display: flex;
    // border-radius: 0 0 8px 8px;
    // border-bottom: 1px solid #000;
    // min-height: 100vh;
  }
}

// @media (min-width: 750px) {
//   .icon {
//     top: -19px;
//     img {
//       width: 338px;
//     }
//   }
// }

.shipinheji {
  div p {
    margin-top: 0;
    margin-bottom: 0;
  }
  .imgBr_flex { 
    position: relative;
    display: flex;
    height: 92px;
    .imgBr_flex_box {
      font-size: 12px;
      color: #a4a3a3;
      line-height: 18px;
    }
    .imgBox {
      // border-radius: 4px 0 0 4px !important;
      // width: 80px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        height: 100%;
      }
    }
    .msg_border {
      flex: 1;
      height: auto !important;
      // border-radius: 0 4px 4px 0 !important;
      padding: 0 8px 0 8px !important;
      box-shadow: none !important;
    }
  }
  .video_title_01 {
    margin: 4px 0;
    line-height: 24px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    vertical-align: middle;
    img{
      margin-right: 5px;
    }
    span{
      vertical-align: middle;
    }
  }
  .p12 {
    padding: 0 12px;
  }
  .pb12 {
    padding-bottom: 10px;
  }
  .imgBox {
    position: relative;
  }
  .imgBr {
    // border-radius: 4px 4px 0 0;
    overflow: hidden;
  }
  .bgLine {
    width: 100%;
    position: absolute;
    bottom: 0;
    color: #fff;
    text-align: right;
    padding-right: 5px;
    line-height: 30px;
    font-size: 12px;
    height: 30px;
    background: linear-gradient(transparent, black);
  }
  .msg_border {
    height: 100px;
    padding: 6px 8px 8px 8px;
    // font-size: 12px;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 0px 4px 0px rgba(131, 146, 158, 0.32);
    display: flex;
    flex-direction: column;
    .video_title {
      img {
        margin: 4px;
      }
      line-height: 24px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      vertical-align: middle;
      span{
        vertical-align: middle;
      }
    }
    .video_message {
      font-size: 12px;
      line-height: 20px;
    }
  }
  .shipinheji_title {
    padding: 12px 0 0 12px;
  }
  .jiugonggeBox_logo {
    img {
      height: 100%;
    }
  }
  .jiugonggeBox_txt {
    position: relative;
  }
  .nav {
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.9);
    color: white;
  }
  .nav p {
    text-align: center;
    line-height: 42px;
  }
  .nav p:hover {
    background-color: #fff;
    color: black;
  }
  .close {
    margin-top: 20px !important;
    height: 32px;
    color: white;
    padding-right: 20px;
    i {
      float: right;
    }
  }
  .type {
    position: relative;
    // padding: 12px;
    background-color: #fff;
    border-radius: 4px;
  }
  .video_message {
    display: flex;
  }
  .video_message span:nth-child(2) {
    margin-left: 5px;
    color: #a4a3a3;
    flex: 1;
  }
  .video_message span:nth-child(3) {
    color: #a4a3a3;
  }
}
</style>