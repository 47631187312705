<template>
  <div v-if="options&&options.data" :style="
    'padding:' + options.data.pageCss.page_css_padding + 
    ';margin:' + options.data.pageCss.page_css_margin + 
    ';border-radius:' + options.data.pageCss.page_css_radius + 
    'px;background-color:' + options.data.pageCss.textBgc+';'+options.data.pageCss.myCss" class="form">
    <div class="form_item">
      <p class="form_title">
        <span>{{options.form_title||'表单'}}</span>
      </p>

      <draggable class="draggableBox" animation="1000" @end="endEventFN" v-model="options.data.menuArr">
        <component :style="'margin-bottom:' + options.data.pageCss.page_css_interval + 'px;'"
          v-for="(item, index) in options.data.menuArr" :key="index" :is="item.type" :index="index">
          <div @click="changeItemBg(item,index)" :class="{draggableBox_poaED: item.showBg}" class="draggableBox_poa ">
          </div>
          <img class="clearItem_solt pointer" @click="clearItem(item, index)" src="@/assets/base_img/video_delate.png"
            alt="">
        </component>
      </draggable>

      <p>
        <span @click="join" class="qd">{{options.button_text||'提交'}}</span>
      </p>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { draggable },

  props: ["index"],
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters(["formwork"]),
    ...mapGetters(["okForm"]),
    ...mapGetters(["workPlace"]),
    options() {
      let options = this.okForm[this.index]
        ? this.okForm[this.index].options
        : { data: JSON.parse(JSON.stringify(this.workPlace.formBox.data)) }

      this.$store.state.formwork.formwork = options.data.menuArr
      return options
    },
  },
  created() {
    const requireComponent1 = require.context(
      "@/components/formwork",
      false,
      /\.vue$/
    );
    requireComponent1.keys().forEach(fileName => {
      let config1 = requireComponent1(fileName);
      let fimeNameChange1 = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
      let componentName1 =
        fimeNameChange1.charAt(0).toLowerCase() +
        fimeNameChange1.slice(1) +
        "Work";
      Vue.component(componentName1, config1.default || config1);
    });
  },
  methods: {
    ...mapActions("workPlace", ["change_workPlace"]),
    chnageVuex() {
      this.change_workPlace({
        index: this.index,
        value: this.form,
      });
    },
    clearItem(item, index) {
      this.options.data.menuArr.splice(index, 1);
      if (this.options.data.menuArr[index - 1]) {
        this.$set(this.options.data.menuArr[index - 1], 'showBg', true);
      } else if (this.options.data.menuArr[index + 1]) {
        this.$set(this.options.data.menuArr[index + 1], 'showBg', true);
      }
      this.$forceUpdate()
      this.$emit("changeWindow");
    },
    endEventFN(argument) {
    },
    changeItemBg(data, index) {
      console.log(data, index)
      console.log(this.options.data.menuArr)
      let arr = this.options.data.menuArr
      arr.forEach((item, i) => {
        this.$set(arr[i], 'showBg', false);
        if (i === index) {
          this.$set(arr[i], 'showBg', true);
        }
      })
      // this.$set(arr[index], 'showBg', true); 
      this.$set(this.$store.state.workPlace.okForm[this.index], 'options', this.options);
      this.$forceUpdate()
    },
    join() {
      return console.log(this.options)
      console.log(this.formwork)
      try {
        this.formwork.forEach(item => {
          if (item.options.require && !item.options.value) {
            let alertTxt = item.options.requireMsg ? item.options.requireMsg : '请填写全部内容！';
            throw new Error(alertTxt);
          }
        })

        this.$message.success('签到成功！');
      } catch (e) {
        this.$message.error(e.message)
      }


    },
  },

}
</script>

<style lang="less" scoped>
.clearItem_solt {
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  width: 14px;
  z-index: 99;
}
.form {
  // width: 100%;
  max-height: 70%;
  margin: auto;
  background-color: #fff;
  padding: 20px;
  position: relative;
  display: flex;
  top: 10%;
}
.form_title {
  font-weight: 600;
  font-size: 20px;
  color: #222222;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
}
.form_item {
  flex: 1;
  overflow: auto;
}
.qd {
  display: inline-block;
  height: 42px;
  line-height: 42px;
  width: 100%;
  background-color: #4988fd;
  border-radius: 24px;
  text-align: center;
  color: white;
  margin-top: 5px;
}
.close {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50px;
}
.draggableBox {
  position: relative;
}
.draggableBox_poa {
  position: absolute;
  width: 100%;
  height: 100%;
  // border: 1px solid #1478f1;
  z-index: 99;
  left: 0%;
  opacity: 0.1;
  top: 0%;
  border-radius: 2px;
}
.draggableBox_poaED {
  background-color: #1478f1;
}
</style>
<style>
.login .ant-form-item-control-wrapper {
  padding-bottom: 8px;
}
.login .ant-form-horizontal {
  margin-bottom: 8px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.5);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
.form_item .video_baseFormBox {
  position: relative;
}
</style>