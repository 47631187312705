import { render, staticRenderFns } from "./second_articleList.vue?vue&type=template&id=1ce3d75c&scoped=true"
import script from "./second_articleList.vue?vue&type=script&lang=js"
export * from "./second_articleList.vue?vue&type=script&lang=js"
import style0 from "./second_articleList.vue?vue&type=style&index=0&id=1ce3d75c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce3d75c",
  null
  
)

export default component.exports